import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Image from '../../../components/Image';

const FarazTamboli = () => (
  <Layout
    title="Alumni Spotlight - Faraz Tamboli"
    className="alumni-center bios"
  >
    {/* Alumni Profile */}
    <Section className="alumni">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../overview">Overview</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../alumni-spotlight">Alumni Spotlight</Link>
          </li>
          <li className="breadcrumb active">Faraz Tamboli</li>
        </ul>
        <Row>
          <Column size={7} offset={1} className="alumni__profile-left pr-2">
            <h2 className="alumni__profile-greeting">Meet Alumni:</h2>
            <h1 className="alumni__profile-title">Faraz Tamboli</h1>
            <h3 className="alumni__profile-type">
              2019 Young Scientist Challenge Finalist
            </h3>
            <h4 className="alumni__profile-project">
              Since participating in the 2019 Challenge, Faraz has continued to
              improve and enhance his entry project, <em>Talk Motion</em>. Faraz
              is currently in 9th grade and enjoys reading fantasy stories,
              making his own music and participating in Mock Trial Club.
            </h4>
            <h2 className="alumni__profile-question">
              Did you continue working on your 3M Young Scientist Challenge
              innovation?
            </h2>
            <p>
              Yes, I have continued to improve <em>TalkMotion</em> — a device
              that can translate sign language to voice and voice to sign
              language. When I presented the prototype in 2019, it was for a
              stand-alone product that translated sign language to voice and
              voice to sign language on a device. During the pandemic, when we
              all had to study over video conferencing software, as many of us
              were unable to go to school, learning became harder for everyone.
              I couldn’t imagine how it would have been for the deaf community.
              So, I decided to implement <em>TalkMotion</em> in the cloud. Since
              then, I have been working on a web portal. Throughout this journey
              I have enjoyed learning web technology and especially the
              challenge of running AI-based models in the cloud.
            </p>
            <h2 className="alumni__profile-question">
              What are your favorite three school subjects?
            </h2>
            <p>
              This year: Biology, Applied Engineering and Principal of Computer
              Science are my favorite subjects.
            </p>
            <h2 className="alumni__profile-question">
              What is your favorite innovation of the last 100 years, and why?
            </h2>
            <p>
              My favorite innovation is Messenger RNA (mRNA) vaccines. What
              impressed me was the speed with which scientists were able to make
              the vaccine for a large population of the world in a
              cost-effective way. Not only did it save us during this pandemic,
              but such technology will be able to protect us from future viruses
              as well as many forms of cancers.
            </p>
            <h2 className="alumni__profile-question">
              What are your plans for the future?
            </h2>
            <p>My dream is to pursue a career in biomedical engineering.</p>
            <h2 className="alumni__profile-question">
              Describe your relationship with your 3M Mentor.
            </h2>
            <p>
              <Link to="/annual-challenge/finalists-mentors-judges/mentors/tesha-alston-dampier">
                Mrs. Tesha Alston Dampier
              </Link>{' '}
              had been one of the most wonderful mentors I have had so far in my
              life. Over the summer she trained and guided me by providing a
              framework to come up with a solution to a challenging problem. She
              has been very supportive throughout my 3M Young Scientist
              Challenge journey. Meeting her at the event was one of the joyous
              moments for me. I never knew that this journey of friendship would
              continue even in the future. I am very thankful for being
              supportive even after the competition. I will never forget her
              teachings and cherish the wonderful experiences I had.
            </p>
          </Column>
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="global__profile-image">
                <Image filename="Faraz-Tamboli-Alumni-Quote.jpg" />
              </div>
              <div className="global__profile-content">
                <h4 className="quote">
                  “The 3M Young Scientist Challenge changed the way I think.
                  Confidence in one’s idea is crucial and without it, I wouldn't
                  have been able to clearly think about executing
                  <em>TalkMotion.”</em>
                </h4>
                <h3>Faraz Tamboli</h3>
                <p>2019 Finalist</p>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Related Alumni Profiles */}
    <Section className="related-profiles">
      <Container>
        <Row>
          <Column fullwidth>
            <h2>Related Alumni</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Anna-Du-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2018 Finalist</span>
                <h3>Anna Du</h3>
                <p>
                  Anna participated in the 2018 3M Young Scientist Challenge and
                  continues to work on her innovation today. Now in 11
                  <sup>th</sup> grade, Anna is extremely passionate about the
                  environment and loves reading and astronomy.
                </p>
                <Link to="../anna-du" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="alumni__bio box-shadow hover imagezoom">
              <Image filename="Katie-Lampo-Alumni-Card.jpg" />
              <div className="p-1.5">
                <span>2017 Finalist</span>
                <h3>Katie Lampo</h3>
                <p>
                  Kathryn is currently a sophomore at Columbia University
                  pursuing a B.S. in Mechanical Engineering with a minor in
                  Applied Physics.
                </p>
                <Link to="../katie-lampo" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="alumni__bio box-shadow hover imagezoom">
              <Image filename="Kaien-Yang-Alumni-Card.jpg" />
              <div className="p-1.5">
                <span>2016 Finalist</span>
                <h3>Kaien Yang</h3>
                <p>
                  Kaien is a sophomore at Standford University majoring in
                  Computer Science with an AI track. He works with robots and
                  machine learning, and plays for Stanford’s club tennis team.
                </p>
                <Link to="../kaien-yang" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default FarazTamboli;
